<template>
  <div>
    <div class="hero-container">
      <div class="swiper mySwiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) of contents" :key="index">
            <img :src="FilteredData(contents[index]).thumb" @click="actCurrentItem(contents, index)" :class="item.isActiveBorder ? borderEnable : ''"/>
          </div>
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div> 
      </div> 
    </div>


      <!-- <div class="swiper-pagination"></div> -->
      <HeroSlide :database="selectedItem" :isActive="0"  loading="lazy"></HeroSlide>
  </div>
</template>

<script>
export default {
  data () {
    return {   
      selectedItem: {},
      borderEnable: 'border-active',
      placeholder_lands: require('@/assets/placeholder-lands.svg'),
    }   
  },
  props: ["contents"],
  mounted () {
    setTimeout(() => {
      document.getElementById(`player_carousel_0`) && document.getElementById(`player_carousel_0`).play();
    }, 2000);

    this.selectedItem = this.FilteredData(this.contents[0])
    var swiper = new window.Swiper(".mySwiper", {
      lazy: true,
        slidesPerView: 4,
        spaceBetween: 20,
  
        // autoplay: {
        //   delay: 20000,
        // },
      pagination: {
        // el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      
    });
    swiper.on('slideChange',  (e) => {     
     console.log(e.activeIndex)
      document.getElementById(`player_carousel_${e.activeIndex}`).play();
      if (e.activeIndex) {
        document.getElementById(`player_carousel_${e.activeIndex - 1}`).pause();
        document.getElementById(`player_carousel_${e.activeIndex + 1}`).pause();
      }
      
    });

    setTimeout(() => {
      document.getElementById(`player_carousel_0`).play();

    }, 2000);


  },
  methods: {
    actCurrentItem (item, activeIndex) {
      this.selectedItem = {};
      this.selectedItem = this.FilteredData(item[activeIndex]);

      this.contents.map((item, index) => {
          if (activeIndex == index) {
            return item.isActiveBorder = true;
          } else {
            return item.isActiveBorder = false;
          }
        })
      
      setTimeout(() => {
        document.getElementById(`player_carousel_0`).play();
      }, 500)
   
    },
    FilteredData(content) {
      let posterurl = this.getPoster(content);
      let description =  content.longdescription || content.shortdescription;
      let title = content.title;
      let videoUrl = content.trailer && content.trailer[0] && content.trailer[0].filelist && content.trailer[0].filelist[0].filename;
      let subgenre = content.tags
        return  {     
              "description" : description,
              "sources" : [ videoUrl ],
              "thumb" : posterurl,
              "title" : title,
              "subgenre": subgenre,
              productionyear: content.productionyear,
              duration: this.calcTimeFormat(content.duration),
              advisory: content.advisory,
              objectid: content.objectid,
              category: content.category,
              genre   : content.genre,
              contentlanguage: content.contentlanguage[0],
              defaulttitle: content.defaulttitle
            }
    },
    calcTimeFormat(val) {
      if (!val) return;
      let totalTime = val;
      let hours = Math.floor(totalTime / 3600);
      let minutes = Math.floor((totalTime - hours * 3600) / 60);

      let showTime =
        (hours ? hours.toString().padStart(2, "0") +  this.$t("hr") : "00h") +
        " " +
        (minutes ? minutes.toString().padStart(2, "0") + (minutes === 1 ? this.$t("min") : this.$t("mins")) : "00s");

      if (showTime == " ") {
        return val + this.$t("secs");
      } else {
        return showTime;
      }
    },
    getPoster(content) {
      // console.log("content", content);
      if (Array.isArray(content.poster)) {
        if (content.poster && content.poster.length) {
          let index = content?.poster?.findIndex((element) => {
            return (element.quality === "HD" || element.quality === "SD") && element.postertype === "LANDSCAPE";
          });
          // console.log("getPoster", index);
          if (index > -1) {
              return content?.poster?.[index]?.filelist?.[1]?.filename || content?.poster?.[index]?.filelist?.[0]?.filename || content?.thumbnail || this.placeholder_lands;
          }
        } 
      } else {
        if (content.thumbnail) {
          return content.thumbnail;
        }
      }
    },
  },
  components: {
    HeroSlide: () => import('@/themeearth/components/dashboard/herocarousel/HeroComponentSlide.vue')
  }
}
</script>

<style scoped>
  .hero-container {
    
    position: absolute;
    top: 65vh;
    /* bottom: 10vw; */
    margin: 0 auto;
    padding: 0rem;
    width: 20rem;
    right: 5rem;
  }
  .swiper-slide img {
    height: 3.1rem;
    width: 4.4rem;
    opacity: 0.8;
    border-radius: 5px;
  }

  .swiper-slide img:hover {
    transform: scale(1.1);
    z-index: 99;
    /* height: 4rem;
    width: 6rem; */
    border: 1px solid;
    cursor: pointer;
    opacity: 1.0;

  }

  .swiper-button-next {
    transform: scale(0.6);
    right: -5px;
  }
  .swiper-button-prev {
    transform: scale(0.6);
    left: -5px;
  }
  .border-active {
    border: 2px solid white;
    opacity: 1 !important;
  }

  .swiper-wrapper {
    padding: 0.2rem;
  }
  
  @media screen and (max-width:900px){
    .hero-container {
      position: absolute;
      max-width: 85%;
      min-width: 23%;
      z-index: 99;
      top: 18rem;
      right: 0%;
      width: 24rem;
    }
  }
    
  

</style>